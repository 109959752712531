import React from "react";

import Layout from "../components/layout";
import StarRating from "../components/star-rating";
import TestimonialAutor from "../components/testimonial-autor";

import nickPhoto from "../images/testimonial-quicka-nick.jpg";
import julienPhoto from "../images/testimonial-tpop-julien.jpeg";
import jimmyPhoto from "../images/testimonial-repobox-jimmy.jpeg";
import rikPhoto from "../images/testimonial-doka-rik.jpg";
import hariPhoto from "../images/testimonial-visalist-hari.jpg";

import IconArrowRight from "../images/inlinesvgs/icons/arrow-right.svg";

export default () => {
	return (
		<Layout
			pageMeta={{
				title: "Sidemail Customer Reviews",
			}}>
			<main className="">
				<div id="reviews" className="aboveFold">
					<div className="container">
						<header>
							<h1>Sidemail Customer Reviews</h1>
						</header>
					</div>
				</div>

				<section className="container mb-70">
					<div className="testimonialBox mb-30">
						<StarRating />
						<h2 className="text-center text-dark">
							Nothing could beat Sidemail
						</h2>
						<p className="text-large">
							“We were absolutely blown away by how awesome the Sidemail
							offering has been for us at Quicka. We looked at rolling our own
							and leveraging existing providers but nothing could beat Sidemail
							for simplicity and ease of use for beautiful transactional emails.
							Thanks Patrik and the team!“
						</p>
						<TestimonialAutor
							name="Nick Glynn"
							position={
								<span className="text-light">
									Co-founder and CTO of{" "}
									<a href="https://www.rapidpaylegal.com.au/">RapidPay Group</a>
								</span>
							}
							imgSrc={nickPhoto}
							imgAlt="Nick Glynn photo"
						/>
					</div>

					<div className="testimonialBox mb-30">
						<StarRating />
						<h2 className="text-center text-dark">Exactly what I needed</h2>
						<p className="text-large">
							“My app messages sent with PHP mail() ended up in spam. I’m no
							email expert, and Sidemail seemed to do exactly what I needed, so
							I replaced mail() with a code snippet from Sidemail, and it just
							worked. I had some trouble setting up DNS, so I contacted the
							Sidemail support and quickly got a helpful response that resolved
							my issue.”
						</p>
						<TestimonialAutor
							name="Rik Schennink"
							position={
								<span className="text-light">
									Founder of <a href="https://pqina.nl/pintura/">PQINA</a>
								</span>
							}
							imgSrc={rikPhoto}
							imgAlt="Rik Schennink photo"
						/>
					</div>

					<div className="testimonialBox mb-30">
						<StarRating />
						<h2 className="text-center text-dark">
							More complete service than anything else on the market
						</h2>
						<p className="text-large">
							“Great service, very easy to use but with many options. More
							complete service than anything else on the market, with a quick
							learning curve: a dream for our use. The Sidemail team is super
							nice and professional, always listening, it's great.”
						</p>
						<TestimonialAutor
							name="Julien Boulogne"
							position={
								<span className="text-light">
									Founder & CEO of <a href="https://www.tpop.com/en/">TPOP</a>
								</span>
							}
							imgSrc={julienPhoto}
							imgAlt="Julien Boulogne photo"
						/>
					</div>

					<div className="testimonialBox mb-30">
						<StarRating />
						<h2 className="text-center text-dark">
							Everything sorta just works
						</h2>
						<p className="text-large">
							“I wanted something a bit easier so I didn’t have to do all the
							template legwork, and this project, I really wanted it to look
							gorgeous... I spent a couple of hours standing up some html
							templates in their editor. From there, it’s as easy as calling up
							the template name and passing in some variables and everything
							sorta just works.“
						</p>
						<TestimonialAutor
							name="Jimmy Lipham"
							position={<span className="text-light">Founder of Repobox</span>}
							imgSrc={jimmyPhoto}
							imgAlt="Jimmy Lipham photo"
						/>
					</div>

					<div className="testimonialBox mb-30">
						<StarRating />
						<h2 className="text-center text-dark">
							By far the easiest email service
						</h2>
						<p className="text-large">
							“Sidemail is by far the easiest email service I have used. I was
							up and sending welcome emails in 10 mins. That’s it. It can send
							transactional, marketing, and newsletter emails. It supports dark
							mode from get-go and is ready for different email clients. All you
							have to do is upload two different logos for light and dark
							themes.“
						</p>
						<TestimonialAutor
							name="Hari Krishna Dalipudi"
							position={
								<span className="text-light">
									Founder of <a href="https://visalist.io/">Visalist</a>
								</span>
							}
							imgSrc={hariPhoto}
							imgAlt="Hari Krishna Dalipudi photo"
						/>
					</div>
				</section>

				<section className="container box-lightBackground box-largeSize-120-100">
					<div className="maxWidth-800 text-center itemCenter">
						<h2 className="">Join businesses that send with Sidemail today</h2>
						<p className="text-large mb-50">
							Sidemail will enable your team to remove hundreds of lines of code
							that you had to previously maintain yourself, while no longer
							having to deal with broken emails or landing in the spam folder.
							Try it free for 7 days.
						</p>
						<a
							href="https://client.sidemail.io/register"
							className="button button--bright button--arrowAnimated">
							Start trial now
							<IconArrowRight className="u-ml1" width={16} height={16} />
						</a>
					</div>
				</section>
			</main>
		</Layout>
	);
};
